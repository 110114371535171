













import { Vue, Component } from 'vue-class-decorator'

@Component({
  metaInfo: {
    title: 'Страница не найдена'
  }
})
export default class Error404 extends Vue {
  goToMain() {
    this.$router.push('/')
  }
}
